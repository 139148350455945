<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="用户名：">
          <el-input style="width: 200px" clearable v-model="searchData.name" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input style="width: 200px" clearable v-model="searchData.name" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="角色：">
          <el-input style="width: 200px" clearable v-model="searchData.name" size="mini" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select style="width: 200px" v-model="searchData.leaveType" placeholder="请选择" size="mini" clearable>
            <el-option label="全天" :value="1"> </el-option>
            <el-option label="上午" :value="2"> </el-option>
            <el-option label="下午" :value="3"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-button style="margin-bottom: 10px" icon="el-icon-plus" plain type="primary" size="mini" @click="handleAdd">新增</el-button>
    <el-table :data="list">
      <el-table-column prop="content" label="用户名" min-width="120"> </el-table-column>
      <el-table-column prop="content" label="姓名" min-width="120"> </el-table-column>
      <el-table-column prop="content" label="角色" min-width="120"> </el-table-column>
      <el-table-column prop="content" label="联系方式" min-width="120"> </el-table-column>
      <el-table-column prop="content" label="状态" min-width="120"> </el-table-column>
      <el-table-column prop="content" label="排序" min-width="120"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="160"> </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 新增编辑 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_update" width="800px">
      <el-form ref="form" :model="formData" label-width="140px">
        <el-form-item label="用户名：" prop="name" :rules="rules">
          <el-input style="width: 300px" size="small" v-model.trim="formData.name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item prop="password" label="登录密码">
          <el-input style="width: 300px" size="small" v-model="formData.password" placeholder="8位以上包含大小写字母、特殊符号、数字组成"></el-input>
          <span style="margin-left: 10px; color: #f56c6c">8位以上包含大小写字母、特殊符号、数字组成</span>
        </el-form-item>
        <el-form-item prop="username" label="姓名">
          <el-input style="width: 300px" size="small" v-model="formData.username" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input style="width: 300px" size="small" v-model="formData.phone" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item prop="role_ids" label="角色">
          <SelectRole style="width: 300px" :multiple="true" v-model="formData.role_ids"></SelectRole>
        </el-form-item>
        <el-form-item label="状态">
          <el-select style="width: 300px" size="small" v-model="formData.status" placeholder="请选择">
            <el-option label="正常" :value="20"></el-option>
            <el-option label="停用" :value="10"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_update = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { getLeaveStuListAPI, getLeaveTeacherListAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],

  data() {
    return {
      rules: {
        account: { required: true, message: '登录不能为空', trigger: 'blur' },
        username: { required: true, message: '姓名不能为空', trigger: 'blur' },
        phone: [
          { required: true, message: '手机号码为空', trigger: 'blur' },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ],
        password: [
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
            message: '密码由8位以上包含大小写字母、特殊符号、数字组成',
            trigger: 'blur'
          }
        ]
      },
      show_update: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        leaveType: '',
        name: '',
        eduId: ''
      },
      total: 0,
      formData: {
        eduId: '',
        name: '',
        startTime: '',
        endTime: '',
        parentPushDelay: 1,
        delayTime: '',
        type: '',
        extraMsg: '',
        signIcon: '',
        sort: 0
      }
    }
  },

  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    handleClickTab(val) {
      if (this.type == val) return
      this.type = val
      this.getList()
    },
    async getList() {
      const { timeSlot } = this
      let params = { ...this.searchData }
      if (timeSlot && timeSlot.length > 0) {
        params.startTime = timeSlot[0]
        params.endTime = timeSlot[1]
      }
      const res = this.type == 0 ? await getLeaveStuListAPI(params) : await getLeaveTeacherListAPI(params)
      this.list = res.records || []
      this.total = res.totalRow || 0
    },
    // del
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增
    handleAdd() {
      this.show_update = true
      this.$nextTick(() => {
        this.formData = {
          eduId: '',
          name: '',
          startTime: '',
          endTime: '',
          parentPushDelay: 1,
          delayTime: '',
          type: '',
          extraMsg: '',
          signIcon: '',
          sort: 0
        }
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_update = false
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getList()
              this.show_update = false
            })
          }
        }
      })
    },
    reset() {
      this.timeSlot = []
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    changeStartTime() {
      console.log(this.formData.startTime)
      if (this.formData.endTime) {
        this.$set(this.formData, 'endTime', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
