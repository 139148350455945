import { http } from '@/http/axios.js'

// 学生请假列表
export function getLeaveStuListAPI(params) {
  return http({
    url: `/manage/edu/stu/leave/page`,
    method: 'get',
    params
  })
}

// 老师请假列表
export function getLeaveTeacherListAPI(params) {
  return http({
    url: `/manage/edu/teacher/leave/page`,
    method: 'get',
    params
  })
}
// 学生请假详情
export function getStuLeaveDetailAPI(id) {
  return http({
    url: `/manage/edu/stu/leave/detail`,
    method: 'get',
    params: { id }
  })
}
// 学生请假详情
export function getTeacherLeaveDetailAPI(id) {
  return http({
    url: `/manage/edu/teacher/leave/detail`,
    method: 'get',
    params: { id }
  })
}
